import React from "react";
import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import { call } from "services/axiosService/core";
import { ChatsTable } from "./chatTable";
import Refresh from "pages/refresh/Refresh";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress, FormLabel, TextField } from "@mui/material";
import { FormControl, InputLabel, Input, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ChatBubble } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequiredLabel = ({ children }) => (
  <FormLabel
    sx={{ display: "block", margin: "5px ", fontSize: "15px" }}
  >
    <span style={{ color: "red", fontSize: "small", marginRight: "5px" }}>*</span>
    {children}
    <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>Required</span>
  </FormLabel>
);

const ChatBotData = () => {
  const [controller] = useMaterialUIController();
  const { profile } = controller;
  const [chats, setChats] = useState([]);
  const [openAddChatModal, setOpenAddChatModal] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const closeAddChatModalHandler = () => {
    setOpenAddChatModal(false);
  };

  const loadChats = () => {
    call(`chats/`, "GET", {})
      .then((res) => {
        console.log("res", res);
        if (res.status == 404) {
          setChats([]);
        } else if (res.status == 200) {
          setChats(res.data.Items);
        } else if (res.status == 440) {
          setChats([]);
          //   setLoadingUser(false);
          //   setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        // Sentry.captureException(err);
      });
  };

  useEffect(() => {
    loadChats();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar title="ChatBotData" />
      <MDBox mt={3}>
        <Card>
          <MDBox display="flex" justifyContent="flex-end" m={2}>
            <MDButton
              onClick={() => setOpenAddChatModal(true)}
              sx={{ width: 300, marginRight: 2 }}
              variant="contained"
              color="primary"
            >
              Add New ChatData
            </MDButton>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              ChatData
            </MDTypography>
            <ChatsTable chats={chats ?? []} loadChats={loadChats} />
          </MDBox>
          {!!openAddChatModal && (
            <AddChatModal
              openAddChatModal={openAddChatModal}
              closeAddChatModalHandler={closeAddChatModalHandler}
              profile={profile}
              loadChats={loadChats}
              setOpenSnackbar={setOpenSnackbar}
              setAlertMessage={setAlertMessage}
              setSeverity={setSeverity}
              setSessionExpired={setSessionExpired}
            />
          )}
        </Card>
      </MDBox>
      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

const AddChatModal = ({
  openAddChatModal,
  closeAddChatModalHandler,
  profile,
  loadChats,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const [chat, setChat] = useState({ question: "", options: [""] });
  const handleQuestionChange = (e) => {
    const { value } = e.target;
    setChat({ ...chat, question: value });
  };

  const handleOptionChange = (e, index) => {
    const { value } = e.target;
    const updatedOptions = [...chat.options];
    updatedOptions[index] = value;
    setChat({ ...chat, options: updatedOptions });
  };

  const addOption = () => {
    const updatedOptions = [...chat.options, ""];
    setChat({ ...chat, options: updatedOptions });
  };

  const removeOption = (index) => {
    const updatedOptions = [...chat.options];
    updatedOptions.splice(index, 1);
    setChat({ ...chat, options: updatedOptions });
  };

  const addChatHandler = (e) => {
    e.preventDefault();
    // Here you can handle the submission of the form
    call(`chats/`, "POST", { body: chat })
      .then((res) => {
        if (res.status == 200) {
          loadChats();
          setAlertMessage("New chat data added successfully");
          setSeverity("success");
          setOpenSnackbar(true);
          closeAddChatModalHandler();
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else {
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
          setSeverity("error");
        }
      })
      .catch((err) => {
        console.log(err);
        setAlertMessage("Error while adding the new chat data");
        setSeverity("error");
        setOpenSnackbar(true);
        closeAddChatModalHandler();
      });
  };

  const isFormValid = () => {
    return chat.question && chat.options.every((option) => option);
  };

  return (
    <Dialog fullWidth={true} open={openAddChatModal} onClose={closeAddChatModalHandler}>
      <DialogTitle>Add New ChatData</DialogTitle>
      <form onSubmit={addChatHandler}>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <RequiredLabel>Question:</RequiredLabel>
            {/* <FormLabel
              sx={{ display: "block", marginBottom: "10px", fontSize: "15px" }}
              htmlFor="question"
            >
              Question:
            </FormLabel> */}
            <MDInput
              id="question"
              type="text"
              placeholder="Enter question"
              value={chat.text}
              onChange={handleQuestionChange}
            />
          </FormControl>

          {chat.options.map((option, optionIndex) => (
            <Grid container spacing={1} alignItems="center" key={optionIndex}>
              <Grid item xs={10}>
                <FormControl fullWidth margin="normal">
                  {/* <FormLabel
                    sx={{ display: "block", marginBottom: "10px", fontSize: "15px" }}
                    htmlFor={`option-${optionIndex}`}
                  >
                    Option {optionIndex + 1}:
                  </FormLabel> */}
                  <RequiredLabel>Option {optionIndex + 1}:</RequiredLabel>
                  <MDInput
                    id={`option-${optionIndex}`}
                    type="text"
                    placeholder={`Enter option ${optionIndex + 1}`}
                    value={option}
                    onChange={(e) => handleOptionChange(e, optionIndex)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                {optionIndex !== 0 && (
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={() => removeOption(optionIndex)}
                    style={{ marginTop: "35px" }}
                  >
                    Remove
                  </MDButton>
                )}
              </Grid>
            </Grid>
          ))}
          <MDButton
            variant="contained"
            color="primary"
            onClick={addOption}
            style={{ marginTop: "10px", marginLeft: "10px" }}
          >
            Add Option
          </MDButton>
          {/* {optionIndex === question.options.length - 1 && (
            <MDButton variant="contained" color="primary" onClick={addOption} style={{ marginTop: '10px', marginLeft:'10px' }}>
              Add Option
            </MDButton>
          )} */}
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            style={{ color: "white", background: "#0AB2D6" }}
            disabled={!isFormValid()}
          >
            Add
          </MDButton>
          <MDButton
            style={{ color: "white", background: "#ED5F72" }}
            onClick={closeAddChatModalHandler}
          >
            Close
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChatBotData;
